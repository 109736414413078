var pp_gemius_identifier = new String('nA3lnYiRpVjHSDIXFeKzbmZVXkc1Lwezm3VfaoseBsn.d7');
var pp_gemius_extraparameters = new Array('gA=seznam.cz/Aplikace');
var pp_gemius_use_cmp = true;

function gemius_pending(i) {
  window[i] =
    window[i] ||
    function () {
      var x = (window[i + '_pdata'] = window[i + '_pdata'] || []);
      x[x.length] = arguments;
    };
}
gemius_pending('gemius_hit');
gemius_pending('gemius_event');
gemius_pending('pp_gemius_hit');
gemius_pending('pp_gemius_event');
(function (d, t) {
  try {
    var gt = d.createElement(t),
      s = d.getElementsByTagName(t)[0];
    gt.setAttribute('async', 'async');
    gt.setAttribute('defer', 'defer');
    gt.src = 'https://gacz.hit.gemius.pl/xgemius.js';
    s.parentNode.insertBefore(gt, s);
  } catch (e) {}
})(document, 'script');
